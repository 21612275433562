import React from 'react';
import './Card.css'
import UX from '../../assets/ux.png'
import WEB from '../../assets/web.png'
import APP from '../../assets/app.png'
import DM from '../../assets/dm.png'
import PHONE from '../../assets/phone.png'
import AGILE from '../../assets/AGILE.png'
import Angular from '../../assets/angular.png'
import ReactLogo from '../../assets/react.png'
import Next from '../../assets/next.png'
import Java from '../../assets/java.png'
import Python from '../../assets/python.png'
import Flutter from '../../assets/flutter.png'
import Tailwind from '../../assets/tailwind.png'
import Facebook from '../../assets/facebook.png'
import Googleads from '../../assets/googleads.png'
import Linkedin from '../../assets/linkedin.png'
import Bing from '../../assets/bing.png'
import Insta from '../../assets/instagram.png'
import UIUX from '../../assets/UIUX icon.png'
import Development from '../../assets/DEVELOPMENT.png';
import Digital from '../../assets/DIGITAL MARKETING.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 450, min: 0 },
        items: 1
    }
};

const Card = () => {
    return (
        <div>
            {/* cards */}
            <div className="cardContainer" id='services'>
                <p className='headCard' >Sit back and relax while we take care of everything</p>
                <div className="cardflexContain">
                    <div className="card1"  >
                        <div className="imgContain"><img src={UX} alt={UX} /></div>
                        <h3>UI/UX DESIGN</h3>
                    </div>
                    <div className="card2"  >
                        <div className="imgContain"><img src={WEB} alt={WEB} /></div>
                        <h3>Website Development</h3>
                    </div>
                    <div className="card3" >
                        <div className="imgContain"><img src={APP} alt={APP} /></div>
                        <h3>App Development</h3>
                    </div>
                    <div className="card4"  >
                        <div className="imgContain"><img src={DM} alt={DM} /></div>
                        <h3>Digital Marketing</h3>
                    </div>
                </div>
            </div>
            {/* ui/ux container */}
            <div className="uiuxContainer">
                <p className='uiheadContain'>UI/UX Design</p>
                <div className="contentflexContain">
                    <div className="fleximgContain">
                        <img src={PHONE} alt={PHONE} />
                    </div>
                    <div className="flexcontentContain" >
                        <ul>
                            <li><b>UIUX</b> (User Interface and User Experience) design and development is a process of designing and developing digital products, such as websites and mobile applications, with a focus on creating a user-friendly and engaging experience for the users.</li><br />
                            <li><b>M S INFO TECH </b> is a company that provides UIUX design and development services to clients. Their team of experts work on every aspect of UIUX design, from conducting user research to creating wireframes, prototypes, and final designs. They also develop the interface, ensuring that it is fully functional and responsive.</li><br />
                            <li><b> M S INFO TECH's</b> goal is to create digital products that are visually appealing, intuitive to use, and meet the specific needs of their clients and their target audience. They work closely with their clients throughout the design and development process to ensure that the final product meets their expectations and goals.</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* methodology */}
            <div className="methodologyContainer"  >
                <p className='methodheadContain' >METHODOLOGY</p>
                <div className="methodimgContain">
                    <img src={AGILE} alt={AGILE} />
                </div>
            </div>
            {/* Service */}
            <div className="ourTechContainer">
                <p className='techHeadContainer' >What I do <br />for my Customers</p>
                <div class="ag-courses_box">
                    <div class="ag-courses_item" >
                        <div class="ag-courses-item_link">
                            <div class="ag-courses-item_bg"></div>
                            <div className="techiconContainer">
                                <img src={UIUX} alt={UIUX} />
                            </div>
                            <div className="techIconbasecontent">
                                <h3 className='techIconTitle'>UI/UX Designer</h3>
                                <p className='paraTech'>Website design involves the creation of visually appealing and user-friendly websites that effectively communicate a brand's message and achieve its business goals. </p>
                            </div>
                        </div>
                    </div>
                    <div class="ag-courses_item" >
                        <div class="ag-courses-item_link" >
                            <div class="ag-courses-item_bg"></div>
                            <div className="techiconContainer">
                                <img src={Development} alt={Development} />
                            </div>
                            <div className="techIconbasecontent">
                                <h3 className='techIconTitle'>Web/App  Development</h3>
                                <p className='paraTech'>Web/App development involves the creation of a website from scratch or modifying an existing one,software applications for mobile devices or desktop computers.  </p>
                            </div>
                        </div>
                    </div>
                    <div class="ag-courses_item" >
                        <div class="ag-courses-item_link">
                            <div class="ag-courses-item_bg"></div>
                            <div className="techiconContainer">
                                <img src={Digital} alt={Digital} />
                            </div>
                            <div className="techIconbasecontent">
                                <h3 className='techIconTitle'>Digital Marketing</h3>
                                <p className='paraTech'>Digital marketing refers to the use of various digital channels such as search engines, social media, email, and websites to promote products or services and engage with customers.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {/* slider tools */}
            <div className="techToolsContainer">
                <Carousel responsive={responsive} autoPlay={true} arrows={false} infinite={true} rewindWithAnimation={true}>
                    <div className='itemsTools'><img src={Angular} alt={Angular} /></div>
                    <div className='itemsTools'><img src={ReactLogo} alt={ReactLogo} /></div>
                    <div className='itemsTools'><img height='100' src={Next} alt={Next}  /></div>
                    <div className='itemsTools'><img src={Flutter} alt={Flutter} /></div>
                    <div className='itemsTools'><img src={Tailwind} alt={Tailwind} /></div>
                    <div className='itemsTools'><img height='100' src={Java} alt={Java} /></div>
                    <div className='itemsTools'><img height='100' src={Python} alt={Python} /></div>
                    <div className='itemsTools'><img height='100' src={Bing} alt={Bing} /></div>
                    <div className='itemsTools'><img height='100' src={Googleads} alt={Googleads} /></div>
                    <div className='itemsTools'><img height='100' src={Linkedin} alt={Linkedin} /></div>
                    <div className='itemsTools'><img height='100' src={Facebook} alt={Facebook} /></div>
                    <div className='itemsTools'><img height='100' src={Insta} alt={Insta} /></div>
                    
                </Carousel>
            </div>

        </div>
    )
}

export default Card