import React, { useEffect } from 'react';
import './App.css'
import Home from './components/Homescreen/Home';
import Navbar from './components/Navbar/Navbar';
import About from './components/about/About';
import Product from './components/product/Product';
import Contact from './components/contact/Contact';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Card from './components/card/Card';
import AOS from 'aos';

function App() {
  useEffect(()=>{
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 200,
    });
  },[])
  return (
    <div >
      <Navbar />
      <Home />
      {/* <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Card />} />
          <Route path='/about' element={<About />} />
          <Route path='/product' element={<Product />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </Router> */}
    </div>
  );
}

export default App;
