import React, { useState } from 'react';
import './Navbar.css'
import Logo from '../../assets/LOGO.png';
import MSLogo from '../../assets/MS LOGO.png';
import {FaBars} from 'react-icons/fa';
import {ImCross} from 'react-icons/im';
import {Link} from 'react-scroll';
import {BiUpArrowAlt} from 'react-icons/bi'
const Navbar = () => {
  const[isMobile,setIsMobile] = useState(false)
  const[changeColor,setChangeColor] = useState(false)

  const scrollChangingColor = ()=>{
      if(window.scrollY >=60){
          setChangeColor(true)
      }else{
          setChangeColor(false)
      }
  }
  window.addEventListener('scroll',scrollChangingColor)
  return (
    <div>
        <nav className={changeColor?'navbarContainerChange':'navbarContainer'} >
          {/* <div className="wrapper"> */}
            <div className="logoContain">
              <Link to="home" spy={true} smooth={true} offset={-100} duration={500}>
                <img src={MSLogo} alt={MSLogo} />
              </Link>
            </div>
            <ul className={isMobile ? 'ul_active':'ul'}>
              <li><Link to="home" spy={true} smooth={true} offset={-100} duration={500}>Home</Link></li>
              <li><Link to="services" spy={true} smooth={true} offset={-130} duration={500}>Services</Link></li>
              <li><Link to="about" spy={true} smooth={true} offset={-130} duration={500}>About us</Link></li>
              <li><Link to="product" spy={true} smooth={true} offset={-130} duration={500}>Products</Link></li>
              <li><Link to="contact" spy={true} smooth={true} offset={-130} duration={500}>Contact</Link></li>
            </ul>
            <button className='btn-con' onClick={()=>{setIsMobile(!isMobile)}}>{isMobile ? <ImCross size={25} />:<FaBars size={25} />}</button>
          {/* </div> */}
        </nav>
        <Link to="home" spy={true} smooth={true} offset={-250} duration={500}>
          <button className='floating-btn'><BiUpArrowAlt size={40} className='arrow-icon' /></button>
        </Link>
    </div>
  )
}

export default Navbar