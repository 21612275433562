import React from 'react'
import './Contact.css'
import Logo from '../../assets/LOGO.png';
import {Link} from 'react-router-dom'
import {BsYoutube,BsTwitter,BsInstagram,BsGlobe} from 'react-icons/bs'
import {FaLinkedinIn} from 'react-icons/fa'


const Contact = () => {
  return (
    <div>
        <div className="contactContainer" id='contact'>
            <p className='contactheadContainer'>CONTACT US</p>
            <img src={Logo} alt={Logo} className='contactLogoContainer' />
            <h2 className='title'>Info Technologies</h2>
            <p className='tagline'> Innovative solutions for a digitally - driven world</p>
            <div className="contactcontentContainer">
                <div className="footer1">
                    <h4>Founder :</h4>
                    <p>Elango Munusakthi</p><br />
                    <h4>Phone</h4>
                    <p>+91 99414 46129</p>
                    <p>+91 63749 06933</p><br />
                    <h4>Email </h4>
                    <p>elango@msinfotechnologies.com</p>                    
                </div>
                <div className="footer2">
                    <h4>M S INFO TECHNOLOGIES</h4>
                    <div className="iconContainer">
                        <a href='https://www.linkedin.com/in/elango-munusakthi-a34b2a26b/' alt='' target='blank'><div className="fbContainer"><FaLinkedinIn size={30} className='icons'/></div></a>
                        <a href='https://twitter.com/MSINFO_TECH' alt='' target='blank'><div className="twitterContainer"><BsTwitter size={37} className='icons' /></div></a>
                        <a href='#' alt='' target='blank'><div className="youtubeContainer"><BsYoutube size={37} className='icons'/></div></a>
                        <a href='https://instagram.com/msinfotechofficial?igshid=MzRlODBiNWFlZA==/' alt='' target='blank'><div className="instaContainer"><BsInstagram size={37} className='icons'/></div></a>
                    </div><br />
                    <h4>M S INNOVATIVE DESIGN</h4>
                    <div className="iconContainer">
                        <a href='https://www.linkedin.com/in/elango-munusakthi-a34b2a26b/' alt=''target='blank'><div className="fbContainer"><FaLinkedinIn size={30} className='icons'/></div></a>
                        <a href='https://twitter.com/MSINFO_TECH' alt='' target='blank'><div className="twitterContainer"><BsTwitter size={37} className='icons' /></div></a>
                        <a href='https://youtube.com/@MSINNOVATIVEDESIGN' alt='' target='blank'><div className="youtubeContainer"><BsYoutube size={37} className='icons'/></div></a>
                        <a href='https://instagram.com/msinnovativedesign?igshid=ODM2MWFjZDg=' alt='' target='blank'><div className="instaContainer"><BsInstagram size={37} className='icons'/></div></a>
                    </div><br />
                    <h4>JILLU CREATIVITY</h4>
                    <div className="iconContainer">
                        <a href='https://www.linkedin.com/in/elango-munusakthi-a34b2a26b/' alt='' target='blank'><div className="fbContainer"><FaLinkedinIn size={30} className='icons'/></div></a>
                        <a href='https://jillucreativity.com/' alt='' target='blank'><div className="twitterContainer"><BsGlobe size={37} className='icons' /></div></a>
                        <a href='https://www.youtube.com/@JILLUCREATIVITY' alt='' target='blank'><div className="youtubeContainer"><BsYoutube size={37} className='icons'/></div></a>
                        <a href='page: https://www.instagram.com/jillu_creativity/?hl=en' alt='' target='blank'><div className="instaContainer"><BsInstagram size={37} className='icons'/></div></a>
                    </div>
                </div>
                <div className="footer3">
                    {/* <h4>Transforming ideas <br />into reality through<br /> software innovation</h4> */}
                    <h4>Products<br /><p><a href='#' alt='' target='blank'>MS SYSTEMS</a></p><p><a href='https://jillucreativity.com/' alt='' target='blank'>JILLU CREATIVITY</a></p></h4>
                     {/* <h5 >JILLU CREATIVITY</h5> */}
                </div>
            </div>
            <div className="copyrightContent">
                <p>Design and Developed by  M S INFO TECHNOLOGIES </p>
                <span>Copyright © 2023  M S Info Technologies. All Rights Reserved.</span>
            </div>
        </div>
    </div>
  )
}

export default Contact