import React from 'react';
import './About.css';
import ABOUT from '../../assets/about.png'

const About = () => {
  return (
    <div>
        <div className="aboutContainer" id='about'>
            <p className='aboutheadContain' >ABOUT US</p>
            <div className="aboutContentContainer" >
                <div className="aboutimgContain" >
                    <img src={ABOUT} alt={ABOUT} />
                </div>
                <div className="aboutcontentContain" >
                    <h4>From conceptualization to execution, we take pride in being the architects of your brand's identity and reputation.</h4>
                    <p>We are a full-service digital agency that offers a range of services to help businesses thrive in the digital age. Our UIUX design services ensure that your digital products are visually appealing, intuitive to use, and provide an exceptional user experience. We specialize in website development, creating custom designs that are optimized for performance, search engine rankings, and user engagement. Our app development services help businesses leverage the power of mobile devices by developing intuitive, feature-rich, and scalable apps for iOS and Android platforms. In addition, our digital marketing services help businesses build their brand, attract new customers, and drive revenue through effective strategies such as SEO, social media marketing, and PPC advertising. Whether you are a startup or an established business, our team of experts is dedicated to helping you achieve your digital goals and stay ahead of the competition.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About