import React from 'react';
import './Home.css'
// import HomeScreen from '../../assets/HOME SCREEN.png'
import HomeScreen from '../../assets/MS.png'
// import HomeScreen from '../../assets/MS 2.png'

import Card from '../card/Card';
import About from '../about/About';
import Product from '../product/Product';
import Contact from '../contact/Contact';
import {Link} from 'react-scroll';

const Home = () => {
  return (
    <div>
       <div className="homeContainer" id='home'>
        <div className="containerOne" data-aos="fade-right" >
            <p>Enhancing digital capabilities for smarter tomorrow</p><br />
            <div className="typingContainer">
              <span className='typing'>Discover Our Innovative Software Solution</span>
            </div><br />
            <Link to='contact' spy={true} smooth={true} offset={160} duration={500}><button className='homeBtn' >Contact us</button></Link>
        </div>
        <div className="containerTwo" >
            <img src={HomeScreen} alt={HomeScreen} />
        </div>
       </div>
      <Card />
      <About />
      <Product />
      <Contact />
    </div>
  )
}

export default Home