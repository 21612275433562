import React from 'react';
import './Product.css';
import PRODUCT from '../../assets/product.png'

const Product = () => {
  return (
    <div id='product'>
        <div className="productContainer">
            <p className='productheadContain' >PRODUCTS</p>
            <div className="productContentContainer">
                <div className="productcontentContain">
                   <p>Jillu Creativity is a female artist promotion company that specializes in promoting cine and serial actresses through videos and graphical designs. Founded in 2019 with just 12 subscribers, the company has demonstrated its digital marketing prowess by growing its subscriber base to over 50,000 in a short period of time. Jillu Creativity is committed to providing exceptional promotion services to its clients and helping them achieve their career goals in the entertainment industry. With a team of creative professionals, the company delivers high-quality videos and graphic designs that showcase the talent and beauty of the actresses. M S Info Tech is dedicated to staying at the forefront of the digital marketing industry and providing innovative solutions to meet the evolving needs of its clients.</p>
                </div>
                <div className="productimgContain">
                    <img src={PRODUCT} alt={PRODUCT} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Product